import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PractitionerSettings } from '@shared/data/models/practitioner.model';

@Injectable({
  providedIn: 'root',
})
export class PractitionerService {
  private httpClient = inject(HttpClient);

  getPractitionerSettings(userId: string, hcsId: string): Observable<PractitionerSettings> {
    return this.httpClient.get<PractitionerSettings>(`${environment.dashboardApi}/portal-api/practitioner/${userId}/${hcsId}/settings`)
  }

  updatePractitionerSettings(userId: string, hcsId: string, settings: PractitionerSettings) {
    return this.httpClient.post<PractitionerSettings>(`${environment.dashboardApi}/portal-api/practitioner/${userId}/${hcsId}/settings`, settings)
  }
}
